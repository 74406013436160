<template>
  <div class="integrations font-inter">
    <div class="d-flex">
      <div class="flex-grow-1 bd-highlight">
        <BaseHeader :title="$t('views.seller.integrations.title')"></BaseHeader>
        <b-breadcrumb :items="breadcrumbItems" class="bg-white m-0 p-0"></b-breadcrumb>
      </div>
      <div class="align-content-center bd-highlight">
        <router-link to="/integracoes/logs-de-envio" class="btn-outline mt-4">{{ $t('views.seller.integrations.show.logs.button') }}</router-link>
      </div>
    </div>
    <div class="divider mt-3"></div>
    <div class="d-flex align-self-start flex-acoes">
      <div class="py-2 pl-4 pr-5 bd-highlight">
        <div class="categories">
          <strong class="title-medium">{{ $t('views.seller.integrations.show.categories.title') }}</strong>
          <b-skeleton-wrapper :loading="loading" class="list">
            <template #loading>
              <b-skeleton class="my-4" width="55%"></b-skeleton>
              <b-skeleton class="my-4" width="95%"></b-skeleton>
              <b-skeleton class="my-4" width="80%"></b-skeleton>
              <b-skeleton class="my-4" width="85%"></b-skeleton>
            </template>
            <ul class="list">
              <li>
                <b-button @click="selectCategory('all')" :class="{'active': selectedCategory === 'all'}" class="filter">{{ $t('views.seller.integrations.show.categories.list.all') }}</b-button>
              </li>
              <li v-for="category in categories" :key="category">
                <b-button @click="selectCategory(category)" :class="{'active': selectedCategory === category}" class="filter">{{ $t(`views.seller.integrations.show.categories.list.${category}`) }}</b-button>
              </li>
            </ul>
          </b-skeleton-wrapper>
        </div>
      </div>
      <div class="py-2 pl-2 flex-grow-1 bd-highlight">
        <strong class="title-medium">
          {{ (selectedCategory == 'all')
            ? $t('views.seller.integrations.show.items.title') 
            : $t(`views.seller.integrations.show.categories.list.${selectedCategory}`)
          }}
        </strong>
        <b-skeleton-wrapper :loading="loading" class="cards">
          <template #loading>
            <div class="card" v-for="i in 12" :key="i">
              <div class="image">
                <b-skeleton-img no-aspect height="50px"></b-skeleton-img>
              </div>
              <div class="content">
                <div class="info">
                  <b-skeleton class="my-2" width="55%"></b-skeleton>
                  <b-skeleton class="my-2" width="90%"></b-skeleton>
                  <b-skeleton class="my-2" width="95%"></b-skeleton>
                </div>
                <div class="action">
                  <b-skeleton type="button" width="55%"></b-skeleton>
                </div>
              </div>
            </div>
          </template>
          <div class="cards">
            <router-link v-for="(integration, i) in filteredIntegrations" :key="i" class="card" :class="{'active': integration.active}" :to="`/integracoes/${integration.id}`">
              <div class="image">
                <img :src="getImgUrl(integration.id)" :alt="integration.name"/>
              </div>
              <div class="content">
                <div class="info">
                  <strong>{{ integration.name }}</strong>
                  <p>{{ $t(`views.seller.integrations.show.items.descriptions.${integration.id}`) }}</p>
                </div>
                <div class="action">
                  <template v-if="integration.active">
                    <template v-if="integration.actived > 0">
                      <b-button class="integrated">{{ $t('views.seller.integrations.show.items.integrated') }} {{ integration.actived > 1 ? `(${integration.actived})` : '' }}</b-button>
                    </template>
                    <template v-else>
                      <b-button class="add-integrations">{{ $t('views.seller.integrations.show.items.call') }}</b-button>
                    </template>
                  </template>
                  <template v-else>
                    <b-button class="no-integrated">{{ $t('views.seller.integrations.show.items.shortly') }}</b-button>
                  </template>
                </div>
              </div>
            </router-link>
          </div>
        </b-skeleton-wrapper>
      </div>
    </div>
  </div>
</template>

<script>
import CallbackService from '@/services/resources/CallbackService';

export default {
  name: "Integrations",
  data: () => {
    return {
      loading: true,
      selectedCategory: 'all',
      categories: [],
      integrations: []
    }
  },
  computed: {
    breadcrumbItems() {
      return [
        { text: 'Dashboard', href: '/dashboard' },
        { text: this.$t('views.seller.integrations.title') }
      ];
    },
    filteredIntegrations() {
      const sortedIntegrations = this.integrations.slice().sort((a, b) => {
        if ((a.actived > 0 && b.actived > 0) || (a.actived === 0 && b.actived === 0)) {
          return a.name.localeCompare(b.name);
        }
        return b.actived - a.actived;
      });

      if (this.selectedCategory === 'all') {
        return sortedIntegrations;
      } else {
        return sortedIntegrations.filter(integration => integration.category_id === this.selectedCategory);
      }
    },
  },
  created() {
    this.fetchIntegrations();
  },
  methods: {
    getImgUrl(image) {
      return `${process.env.VUE_APP_CREATORS_ASSETS_URL}/integrations/${image}.png`;
    },
    populateCategories(integrations) {
      let categorySet = new Set();
      integrations.forEach(integration => {
        categorySet.add(integration.category_id);
      });
      this.categories = Array.from(categorySet).sort();
    },
    selectCategory(category) {
      this.selectedCategory = category;
    },
    fetchIntegrations() {
      this.loading = true;
      CallbackService.build().read('integrations/all')
      .then(response => {
        this.populateCategories(response);
        this.integrations = response;
        this.loading = false;
      })
      .catch(() => {
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.integrations {

  .title-medium {
    margin-bottom: 24px;
  }

  .categories {
    position: sticky;
    width: 250px;
    top: 24px;

    ul.list {
      .filter {
        background: transparent;
        color: #64748B;
        font-weight: 500;
        font-size: 16px;
        border-radius: 8px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        border: none;
        height: 40px;
        width: 100%;

        &.active {
          background: #F3F4F6;
          color: #0854C5;
        }

        &:hover {
          color: #0854C5;
        }
      }
    }
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-gap: 24px;

    .card {
      border-radius: 10px;
      border: solid 1px #CCC;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      cursor: default;
      pointer-events: none;

      &.active {
        pointer-events: auto;
      }

      .image {
        min-height: 104px;
        flex-shrink: 0;
        padding: 24px;

        img {
          height: 55px;
          margin: 0 auto;
        }
      }

      .content {
        flex-grow: 1;
        border-top: solid 1px #CCC;
        background: #f9fafb;
        padding: 32px 24px 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .info {
          margin-bottom: 24px;

          strong {
            font-weight: 600;
            font-size: 16px;
            display: block;
            white-space: nowrap;                  
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 4px;
          }
          p {
            font-size: 12px;
            line-height: 1.4;
            color: #6B7280;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }

        .action {
          .add-integrations, .integrated, .no-integrated {
            padding: 0 10px;
            height: 32px;
            border-radius: 6px;
            font-size: 12px;
            font-weight: 500;
            border: solid 1px #0854C5;
            color: #0854C5;
            background: transparent;
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }

          .integrated {
            background: #D1FAE5;
            border-color: #D1FAE5;
            color: #059669;
            font-weight: 600;
          }

          .no-integrated {
            background: #FEF3C7;
            border-color: #FEF3C7;
            color: #D97706;
            font-weight: 600;
          }
        }
      }

      &:hover {
        text-decoration: none;
      
        &.active {
          cursor: pointer;
          box-shadow: 0 4px 10px 0 #0000001A;
          transition: box-shadow 0.3s;

          .content .action .add-integrations {
            background: #0854C5;
            color: #FFF;
          }
        }
      }
    }
  }
}
</style>